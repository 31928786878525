import type { KarrotBridge } from "@daangn/karrotbridge";
import {
  BridgeInvokeError,
  MissingValueError,
} from "@daangn/plantae-error-types";
import {
  initStore,
  refreshToken,
  resolveToken,
} from "@daangn/webview-auth-core";
import pRetry from "p-retry";
import type { Plugin } from "plantae";

import checkVersionCode from "./checkVersionCode";
import {
  AuthTokenExpiredError,
  InternalServerError,
  RetryError,
  UnauthorizedError,
} from "./types/errors";

type PluginContext = {
  isAccessTokenSupported: boolean;
  authToken?: Promise<string>;
};

export default function authPlugin({
  bridge,
  options: { fallbackAuthToken, TEST },
}: {
  bridge: KarrotBridge;
  options: {
    fallbackAuthToken:
      | {
          headerName?: string;
          value?: string;
        }
      | boolean;
    TEST?: {
      resolveToken?: () => string | Promise<string>;
      refreshToken?: ({
        oldToken,
      }: {
        oldToken: string;
      }) => string | Promise<string>;
    };
  };
}): Plugin<PluginContext> {
  initStore({
    bridge,
  });

  const isAccessTokenSupported = checkVersionCode();

  const context: PluginContext = {
    isAccessTokenSupported,
  };

  if (fallbackAuthToken) {
    context.authToken =
      fallbackAuthToken === true || !fallbackAuthToken.value
        ? pRetry(
            () =>
              bridge
                .getUserInfo({})
                .catch((e) => {
                  console.warn(
                    "Retrying getUserInfo, visibility: ",
                    document.visibilityState
                  );
                  const err = new BridgeInvokeError(e.message);
                  err.stack = e.stack;
                  throw err;
                })
                .then((res) => {
                  const token = res?.info?.user?.authToken;

                  if (!token) {
                    console.warn(
                      "Failed to get auth token, visibility:",
                      document.visibilityState
                    );

                    throw new MissingValueError(
                      `Auth token does not exist in message: ${JSON.stringify(
                        res
                      )}`
                    );
                  }

                  return token;
                }),
            {
              retries: 3,
              minTimeout: 16,
            }
          )
        : Promise.resolve(fallbackAuthToken.value);
  }

  return {
    name: "plugin-auth",
    context,
    hooks: {
      beforeRequest: async (req) => {
        if (isAccessTokenSupported) {
          try {
            let resolvedToken = TEST?.resolveToken
              ? await TEST.resolveToken()
              : await resolveToken();

            req.headers.set("Authorization", `Bearer ${resolvedToken}`);
          } catch {
            console.error("Failed to resolve token");
          }
        }

        if (fallbackAuthToken) {
          const header =
            fallbackAuthToken === true || !fallbackAuthToken.headerName
              ? "X-Auth-Token"
              : fallbackAuthToken.headerName;

          req.headers.set(header, await context.authToken!);
        }

        return req;
      },
      afterResponse: async (res, req, retry) => {
        const { status } = res;

        if (
          status === 200 &&
          res.headers.get("Content-Type")?.includes("application/json")
        ) {
          const result = await res.json();

          const code = result?.status?.code;

          switch (code) {
            case "not_authenticated":
              throw new AuthTokenExpiredError(JSON.stringify(result));
            case "internal_server_error":
              throw new InternalServerError(JSON.stringify(result));
            default:
              return new Response(JSON.stringify(result), res);
          }
        }

        if (!isAccessTokenSupported || status !== 401) {
          return res;
        }

        const oldTokenHeader = req.headers.get("Authorization");

        if (!oldTokenHeader) {
          // unreachable
          throw new UnauthorizedError("Authorization header is not found");
        }

        const oldToken = oldTokenHeader.split(" ")[1];

        try {
          let refreshedToken = TEST?.refreshToken
            ? await TEST.refreshToken({
                oldToken,
              })
            : await refreshToken({
                oldToken,
              });

          req.headers.set("Authorization", `Bearer ${refreshedToken}`);
        } catch {
          console.error("Failed to refresh token");
          return res;
        }

        return retry(req).then((retryResponse) => {
          if (retryResponse.status === 401) {
            throw new RetryError("Failed to retry with refreshed token");
          }

          return retryResponse;
        });
      },
    },
  };
}
