import type { DialogApi } from "@daangn/sprout-hooks-dialog";
import React from "react";

export const AlertDialogContext = React.createContext<{
  api: DialogApi;
} | null>(null);

export const useAlertDialogProps = () => {
  const context = React.useContext(AlertDialogContext);
  if (!context) {
    throw new Error(
      `AlertDialog, AlertDialogTrigger cannot be rendered outside the AlertDialogWrapper`,
    );
  }
  return context;
};
